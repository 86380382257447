import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, type Theme } from '@mui/material';
import axios from 'axios';
import Menu, { MenuItem } from 'rc-menu';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Input from '../../Formik/Input';
import Switch from '../../Switch';
import DropdownV2 from '../../DropdownV2/DropdownV2';
import { type TransactionAlerts, type TransactionAlertName } from '../../../../helpers/types';
import { appState } from '../../../..';
import { openToast } from '../../../../helpers/toast';
import { type Report } from '../../../../types/reports.d';
import { fetchGeotabVehicles } from '../../../../helpers/api';
import { AlertType, alertTypes } from '../../../../helpers/ReportHelpers/ReportAlerts';

const theme: Theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

interface Props {
  fetchScheduledReports?: () => void;
  method: 'edit' | 'schedule';
  existingScheduledReport?: Report | null;
  handleCloseModal: () => void;
}

const today: Date = new Date();
const lastWeek: Date = new Date(today);
lastWeek.setDate(today.getDate() - 7);

const ScheduleReportHeader: React.FC = () => (
  <div className="flex items-center gap-x-2">
    <Icon name="calendar" />
    <p>{t('Schedule Report')}</p>
  </div>
);

interface GeotabUser {
  id: string;
  username: string;
  database: string;
  password?: string;
  sessionId?: string;
}

interface User {
  id: string;
  geotab_user: GeotabUser;
  email: string;
}

interface TransformedUser {
  id: string;
  geotabUsername: string;
  email: string;
}

const transformData = (users: User[]): TransformedUser[] => {
  return users
    .filter(user => user.geotab_user?.username)
    .map(user => ({
      id: user.id,
      geotabUsername: user.geotab_user.username,
      email: user.email
    }));
};

const ScheduleReportModal: React.FC<Props> = ({
  fetchScheduledReports,
  handleCloseModal,
  method,
  existingScheduledReport = null
}) => {
  const [users, setUsers] = useState<TransformedUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<TransformedUser>({ id: '', geotabUsername: '', email: '' });
  const [existingReport, setExistingReport] = useState<Report | null>(existingScheduledReport);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [enableAllIds, setEnableAllIds] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [reportType, setReportType] = useState<ReportType>(t('Vehicles'));
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [includedData, setIncludedData] = useState<any>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [reportTypeString, setReportTypeString] = useState<string>('vehicle');
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduledDay, setScheduledDay] = useState<any>(existingScheduledReport ? (String(existingScheduledReport?.day[0])).toUpperCase() + String(existingScheduledReport?.day.slice(1)) : 'Monday');
  const [interval, setInterval] = useState<string>(existingScheduledReport ? (String(existingScheduledReport?.interval[0]).toUpperCase() + String(existingScheduledReport?.interval.slice(1))) : 'Weekly');
  const [alerts, setAlerts] = useState<TransactionAlerts>(existingScheduledReport?.alerts ?? {
    no_vehicle_location: false,
    location_mismatch: false,
    fuel_level_mismatch: false,
    fuel_type_mismatch: false,
    filling_time_alert: false,
    filling_percent_alert: false,
    tank_capacity_mismatch: false,
    out_of_state_alert: false
  });

  const fetchUsers = async (): Promise<void> => {
    try {
      const url: string = `${process.env.REACT_APP_API_URL}/api/users-minimized/${appState.accountId.value}`;
      const response = await axios.get(url);
      const transformedUsers = transformData(response.data.data);
      setUsers(transformedUsers);
      if (existingReport) {
        const userToSelect = transformedUsers.find(user => user.email === existingReport.email);
        setSelectedUser(userToSelect ?? { id: '', geotabUsername: '', email: '' });
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
    }
  };

  const getVehicleIdsFromGroups = async (): Promise<object> => {
    let vehicleIds: any[] = [];
    const includedGroupIds: any[] = [];
    includedData.forEach((group: any) => {
      includedGroupIds.push(group.id);
    });
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/geotab-vehicle-ids-by-groups`;
      const response = await axios.post(url, {
        group_ids: includedGroupIds
      });
      vehicleIds = response.data.data;
    } catch (err) {
      console.log(err);
    }
    return vehicleIds;
  };

  const getIds = (): object => {
    const ids: any[] = [];
    includedData.forEach((item: any) => {
      ids.push(item.id);
    });
    return ids;
  };

  const getGeotabUserWithUsername = async (username: string): Promise<GeotabUser | undefined> => {
    const url = `${process.env.REACT_APP_API_URL}/api/get-geotab-user-with-credentials`;
    const params = {
      userName: username,
      database: appState.database
    };
    try {
      const geotabUserResponse = await axios.post(url, params);
      return geotabUserResponse.data.data;
    } catch (error) {
      return undefined;
    }
  };

  const createScheduledReport = async (values: any): Promise<void> => {
    values.includedData = includedData;
    const geotabUser = await getGeotabUserWithUsername(selectedUser.geotabUsername);
    if (!geotabUser) {
      openToast({
        type: 'error',
        label: 'Cannot authenticate geotab user. Make sure that the geotab username is linked to a user in this App. The designated user must sign into the app to finish authentication.',
        autoClose: 5000,
        theme: 'dark'
      });
      return;
    }
    console.log(appState.authMessage);
    console.log(appState.authMessage.value);
    console.log(appState.companyGuid);
    const geotabUserParams = {
      credentials: {
        database: geotabUser.database,
        sessionId: geotabUser.sessionId,
        userName: geotabUser.username,
        password: geotabUser.password
      },
      company_guid: appState.companyGuid
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/validate-geotab-credentials`, geotabUserParams);
    } catch (error: any) {
      openToast({
        type: 'error',
        label: 'Cannot authenticate geotab user. Make sure that the user is linked to a user in this App and that they have been successfully logged in.',
        autoClose: 5000,
        theme: 'dark'
      });
      return;
    }
    let selectedReportInputs;
    if (enableAllIds) {
      selectedReportInputs = 'all';
    } else {
      if (reportTypeString === 'vehicle-group') {
        selectedReportInputs = await getVehicleIdsFromGroups();
      } else {
        selectedReportInputs = getIds();
      }
    }
    const url = `${process.env.REACT_APP_API_URL}/api/report-schedules`;
    const params = {
      account_id: appState.accountId.value,
      user_id: selectedUser.id,
      geotab_user_id: geotabUser.id,
      email: selectedUser.email,
      geotab_username: selectedUser.geotabUsername,
      day: scheduledDay.toLowerCase(),
      interval: interval.toLowerCase(),
      alerts: JSON.stringify(alerts),
      selected_ids: selectedReportInputs === 'all' ? selectedReportInputs : JSON.stringify(selectedReportInputs),
      type: reportTypeString,
      vehicle_groups: reportTypeString === 'vehicle-group'
        ? JSON.stringify(includedData.map((data: any) => {
          return data.group_id;
        }))
        : null
    };
    console.log(params);
    try {
      await axios({
        url,
        data: params,
        method: 'post'
      });
      openToast({ type: 'success', label: t('Report scheduled'), autoClose: 2000, theme: 'dark' });
    } catch (err) {
      console.log(err);
      setLoading(false);
      openToast({ type: 'error', label: t('Error scheduling report'), autoClose: 2000, theme: 'dark' });
    }
  };

  function handleScheduleReportError (errorMessage: string): void {
    setErrorMessage(errorMessage);
    setShowError(true);
    setLoading(false);
    setDisabled(false);
  }
  const scheduleReport = async (values: any, reloadReports: () => void): Promise<void> => {
    setLoading(true);
    setShowError(false);
    if (!selectedUser) {
      handleScheduleReportError(t('Must select a user'));
      return;
    }
    if (!includedData.length && !enableAllIds) {
      handleScheduleReportError(t('No items selected'));
      return;
    }
    let valid = false;
    for (const alert in alerts) {
      if (alerts[alert as TransactionAlertName]) valid = true;
    }
    if (!valid) {
      handleScheduleReportError(t('No alerts selected'));
      return;
    }
    createScheduledReport(values).then(() => {
      handleCloseModal();
      setLoading(false);
      reloadReports();
    }).catch(err => {
      console.log(err);
      setDisabled(false);
    });
  };

  const editScheduledReport = async (values: any, reloadReports: () => void): Promise<void> => {
    setLoading(true);
    setShowError(false);
    if (!selectedUser) {
      handleScheduleReportError(t('Must select a user'));
      return;
    }
    let valid = false;
    for (const alert in alerts) {
      if (alerts[alert as TransactionAlertName]) valid = true;
    }
    if (!valid) {
      handleScheduleReportError(t('No alerts selected'));
      return;
    }
    values.includedData = includedData;
    const url = `${process.env.REACT_APP_API_URL}/api/report-schedules/${existingReport?.id}`;

    let selectedReportInputs;
    if (enableAllIds) {
      selectedReportInputs = 'all';
    } else {
      if (reportTypeString === 'vehicle-group') {
        selectedReportInputs = await getVehicleIdsFromGroups();
      } else {
        selectedReportInputs = getIds();
      }
    }

    const params = {
      account_id: appState.accountId.value,
      email: values.user,
      day: scheduledDay.toLowerCase(),
      interval: interval.toLowerCase(),
      alerts: JSON.stringify(alerts),
      selected_ids: selectedReportInputs === 'all' ? selectedReportInputs : JSON.stringify(selectedReportInputs),
      type: reportTypeString,
      vehicle_groups: reportTypeString === 'vehicle-group'
        ? JSON.stringify(includedData.map((data: any) => {
          return data.group_id;
        }))
        : null
    };
    try {
      await axios.put(url, params);
      handleCloseModal();
      setLoading(false);
      reloadReports();
      openToast({ type: 'success', label: t('Report updated'), autoClose: 2000, theme: 'dark' });
    } catch (err) {
      console.log(err);
      openToast({ type: 'error', label: t('Error updating report'), autoClose: 2000, theme: 'dark' });
      setDisabled(false);
    }
  };

  const addAlert = (checked: boolean, alert: string): void => {
    const updateAlerts = structuredClone(alerts);
    updateAlerts[alert] = checked;
    setAlerts(updateAlerts);
  };
  const getTransformedVehicles = (vehicles: any): any => {
    const transformedVehicles: any[] = [];
    const updatedIncludedData: any[] = [];
    vehicles.forEach((vehicle: any) => {
      const transformedVehicle = {
        id: vehicle.device_id,
        name: vehicle.name,
        vehicle_id: vehicle.id,
        include: existingReport ? existingReport?.selectedIds?.includes(String(vehicle.device_id)) : false
      };
      if (transformedVehicle.include) {
        updatedIncludedData.push(transformedVehicle);
      }
      transformedVehicles.push(transformedVehicle);
    });
    setIncludedData(updatedIncludedData);
    return transformedVehicles;
  };

  const getTransformedGroups = (groups: any): any => {
    const transformedGroups: any[] = [];
    const updatedIncludedData: any[] = [];
    groups.forEach((group: any) => {
      const transformedGroup = {
        id: group.id,
        group_id: group.group_id,
        name: group.name,
        include: existingReport
          ? existingReport?.vehicleGroups
            ? existingReport?.vehicleGroups.includes(String(group.group_id))
            : false
          : false
      };
      if (transformedGroup.include) {
        updatedIncludedData.push(transformedGroup);
      }
      transformedGroups.push(transformedGroup);
    });
    setIncludedData(updatedIncludedData);
    return transformedGroups;
  };

  const getTransformedFuelCards = (fuelCards: any): any => {
    const transformedFuelCards: any[] = [];
    const updatedIncludedData: any[] = [];
    fuelCards.forEach((fuelCard: any) => {
      const transformedFuelCard = {
        id: fuelCard.id,
        name: fuelCard.unique_identifier,
        vehicle_name: fuelCard.vehicle_name,
        device_id: fuelCard.device_id,
        fcp: fuelCard.fcp,
        fuel_type: fuelCard.fuel_type,
        include: existingReport ? existingReport?.selectedIds.includes(fuelCard.id) : false
      };
      if (transformedFuelCard.include) {
        updatedIncludedData.push(transformedFuelCard);
      }
      transformedFuelCards.push(transformedFuelCard);
    });
    setIncludedData(updatedIncludedData);
    return transformedFuelCards;
  };

  const getTransformedDrivers = (drivers: any): any => {
    const transformedDrivers: any[] = [];
    const updatedIncludedData: any[] = [];
    drivers.forEach((driver: any) => {
      const transformedDriver = {
        id: driver.id,
        name: driver.name,
        include: existingReport ? existingReport?.selectedIds.includes(driver.id) : false
      };
      if (transformedDriver.include) {
        updatedIncludedData.push(transformedDriver);
      }
      transformedDrivers.push(transformedDriver);
    });
    setIncludedData(updatedIncludedData);
    return transformedDrivers;
  };

  const getGeotabVehicles = async (): Promise<void> => {
    setReportTypeString('vehicle');
    setLoading(true);
    try {
      const vehicleParams = {
        account_id: appState.accountId.value
      };
      const response = await fetchGeotabVehicles();
      if (response) {
        const sortedVehicles = [...response].sort((vehicleA: any, vehicleB: any) => {
          if (vehicleA.name < vehicleB.name) return -1;
          if (vehicleA.name > vehicleB.name) return 1;
          return 0;
        });
        setTransformedData(getTransformedVehicles(sortedVehicles));
        setRowCount(response.length);
      }
    } catch (error) {
      console.log('Error fetching geotab vehicles:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchVehicleGroups = async (): Promise<void> => {
    setReportTypeString('vehicle-group');
    const url = `${process.env.REACT_APP_API_URL}/api/geotab-vehicle-groups?filter=account_id:${appState.accountId.value}(eq)`;
    setLoading(true);
    try {
      const response = await axios.get(url);
      if (response.data.data) {
        const sortedGroups = [...response.data.data].sort((groupA: any, groupB: any) => {
          if (groupA.name < groupB.name) return -1;
          if (groupA.name > groupB.name) return 1;
          return 0;
        });
        setTransformedData(getTransformedGroups(sortedGroups));
        setRowCount(response.data.data.length);
      }
    } catch (error) {
      console.log('Error fetching geotab groups:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFuelCards = async (): Promise<void> => {
    setReportTypeString('fuel-card');
    setLoading(true);
    try {
      const params = {
        account_id: appState.accountId.value
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/fuel-cards-minimized`, params);
      if (response.data.data) {
        const sortedFuelCards = [...response.data.data].sort((fuelCardA: any, fuelCardB: any) => {
          if (fuelCardA.unique_identifier < fuelCardB.unique_identifier) return -1;
          if (fuelCardA.unique_identifier > fuelCardB.unique_identifier) return 1;
          return 0;
        });
        setTransformedData(getTransformedFuelCards(sortedFuelCards));
        setRowCount(response.data.data.length);
      }
    } catch (error) {
      console.error('Error fetching fuel cards:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDrivers = async (): Promise<void> => {
    setReportTypeString('driver');
    const url = `${process.env.REACT_APP_API_URL}/api/drivers?filter=account_id:${appState.accountId.value}(eq)`;
    setLoading(true);
    try {
      const response = await axios.get(url);
      if (response.data.data) {
        const sortedDrivers = [...response.data.data].sort((driverA: any, driverB: any) => {
          if (driverA.name < driverB.name) return -1;
          if (driverA.name > driverB.name) return 1;
          return 0;
        });
        setTransformedData(getTransformedDrivers(sortedDrivers));
        setRowCount(response.data.data.length);
      }
    } catch (error) {
      console.error('Error fetching drivers:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchUsers();
    if (method === 'edit' && existingReport) {
      switch (existingReport.type) {
        case 'vehicle':
          void getGeotabVehicles();
          break;
        case 'vehicle-group':
          void fetchVehicleGroups();
          break;
        case 'fuel-card':
          void fetchFuelCards();
          break;
        case 'driver':
          void fetchDrivers();
          break;
      }
    } else {
      void getGeotabVehicles();
    }
  }, []);
  const fetchReportType = (type: string): void => {
    setIncludedData([]);
    if (method === 'edit' && existingReport) {
      setExistingReport({ ...existingReport, selectedIds: [], vehicleGroups: [] });
    }
    if (type === 'Vehicles' || type === 'Vehículos') {
      void getGeotabVehicles();
    } else if (type === t('Vehicle Groups')) {
      void fetchVehicleGroups();
    } else if (type === t('Fuel Cards')) {
      void fetchFuelCards();
    } else if (type === t('Drivers')) {
      void fetchDrivers();
    }
  };

  type ColumnsConfig = Record<string, Array<{
    name: string,
    headerText: string,
    width: number
  }>>;

  const columnsConfig: ColumnsConfig = {
    [t('Vehicles')]: [
      { name: 'name', headerText: '', width: 250 },
      { name: 'selected', headerText: t('Selected'), width: 200 }
    ],
    [t('Vehicle Groups')]: [
      { name: 'name', headerText: '', width: 250 },
      { name: 'selected', headerText: t('Selected'), width: 200 }
    ],
    [t('Fuel Cards')]: [
      { name: 'name', headerText: '', width: 250 },
      { name: 'selected', headerText: t('Selected'), width: 200 }
    ],
    [t('Drivers')]: [
      { name: 'name', headerText: '', width: 250 },
      { name: 'selected', headerText: t('Selected'), width: 200 }
    ]
  };

  type ReportType = keyof ColumnsConfig;

  function getColumnsByReportType (reportType: ReportType): ReturnType<typeof createColumns> {
    const fields = columnsConfig[reportType];
    if (!fields) {
      throw new Error(`Invalid report type: ${reportType}`);
    }
    return createColumns(reportType, fields);
  }

  const createColumns = (headerName: any, fields: any): any => fields.map((field: any) => ({
    field: (field.name),
    headerName: `${t(headerName)} ${t(field.headerText)}`,
    width: field.width,
    ...(field.name === 'selected' && {
      renderCell: (params: any) => {
        return (
          <Switch
            key={params.row.id}
            checked={params.row.include}
            onClick={(newValue) => {
              newValue
                ? setIncludedData(includedData.concat(params.row))
                : setIncludedData(includedData.filter((item: any) => item.id !== params.row.id));
              setTransformedData(
                transformedData.map((item) =>
                  item.id === params.row.id
                    ? {
                        ...item,
                        include: newValue
                      }
                    : item
                )
              );
            }}
          />
        );
      }
    })
  }));

  return (
    <>
      <Modal
        header=
        {<ScheduleReportHeader />}
        show={true}
        onHide={() => {
          handleCloseModal();
          setTimeout(() => {
            setDisabled(false);
          }, 500);
        }}
        maxWidth={1000}
      >
      {loading && (
        <div
           className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
        >
          <CircularProgress/>
        </div>
      )}
        <Formik
          enableReinitialize
          initialValues={{
            id: existingReport?.id ?? ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            setDisabled(true);
            switch (method) {
              case 'edit':
                if (fetchScheduledReports) {
                  void editScheduledReport(values, fetchScheduledReports);
                }
                break;
              case 'schedule':
                if (fetchScheduledReports) {
                  void scheduleReport(values, fetchScheduledReports);
                }
                break;
              default:
                break;
            }
            setSubmitting(false);
          }}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                <div className="flex gap-x-6">
                  <div className="flex flex-col gap-y-6 flex-1">
                    <Input
                      name="transactionsFrom"
                      type="select"
                      label="Include transactions from"
                      defaultValue={
                        method === 'edit' && existingReport
                          ? t(existingReport?.type.includes('-')
                            ? String(existingReport?.type.replace('-', ' ').split(' ').map((word: string) => word.slice(0, 1).toUpperCase() + word.slice(1)).join(' ')) + 's'
                            : String(existingReport?.type[0]).toUpperCase() + String(existingReport?.type.slice(1)) + 's')
                          : t('Vehicles')
                      }
                      options={[
                        t('Vehicles'),
                        t('Vehicle Groups'),
                        t('Fuel Cards'),
                        t('Drivers')
                      ]}
                      onChange={setReportType}
                      handleChange={fetchReportType}
                    />
                    <div className="flex items-start flex-col gap-y-5">
                      <div className="flex items-center gap-x-3">
                        <Switch
                          label={t('All ' + reportType + ' (includes all current and future ' + reportType.toLowerCase() + ')')}
                          checked={enableAllIds}
                          onClick={() => {
                            setEnableAllIds(!enableAllIds);
                          }}
                        />
                      </div>
                      {!enableAllIds && <Input
                        type="text"
                        name="searchQuery"
                        label={`${t('Search')}: ${transformedData.filter((item) => {
                          return item.name.toUpperCase().includes(searchValue.toUpperCase());
                        }).length} ${t('results')}`}
                        setState={setSearchValue}
                        stateValue={searchValue}
                      />}
                    </div>
                    {!enableAllIds && <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <div ref={gridRef}>
                        <DataGrid
                          hideFooter={true}
                          disableDensitySelector={true}
                          hideFooterPagination={true}
                          localeText={{
                            noRowsLabel: t('No rows'),
                            toolbarColumns: t('Columns'),
                            toolbarColumnsLabel: t('Columns'),
                            toolbarDensity: t('Density'),
                            toolbarDensityLabel: t('Density'),
                            toolbarDensityCompact: t('Compact'),
                            toolbarDensityStandard: t('Standard'),
                            toolbarDensityComfortable: t('Comfortable'),
                            toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                            MuiTablePagination: {
                              labelRowsPerPage: t('Rows per Page'),
                              labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                            }
                          }}
                          rowCount={rowCount}
                          rows={transformedData.filter((item) => {
                            return item.name.toUpperCase().includes(searchValue.toUpperCase());
                          })}
                          paginationMode="server"
                          columns={getColumnsByReportType(t(reportType))}
                          disableColumnFilter
                          disableColumnMenu
                          disableRowSelectionOnClick
                          density="compact"
                          slotProps={{
                            toolbar: { // Disable Export Option
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true }
                            }
                          }}
                          slots={{ toolbar: GridToolbar }}
                          className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                          sx={{
                            height: '20rem',
                            '& .MuiDataGrid-row': {
                              borderRadius: '80px',
                              backgroundColor: '#242838',
                              marginTop: '4px',
                              borderBottom: 'none'
                            },
                            '& .MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            '& .MuiDataGrid-cell': {
                              border: 'none'
                            },
                            '& .MuiDataGrid-columnHeaders': {
                              borderRadius: '80px',
                              backgroundColor: '#242838',
                              borderBottom: 'none',
                              marginBottom: '10px'
                            },
                            border: 0
                          }}
                        />
                      </div>
                    </ThemeProvider>}
                  </div>
                  <div className="w-px min-w-[1px] bg-dark-2"></div>
                  <div className="flex flex-col gap-y-8 pr-6">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-y-2 w-full">
                        <p className="text-xs">User</p>
                        <DropdownV2
                          key="user-dropdown"
                          menu={(
                            <Menu
                              key="user-menu"
                            >
                              {users.map((user: TransformedUser, index: number) => {
                                return (
                                  <MenuItem
                                    key={user.id + `${index}`}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setSelectedUser(user);
                                    }}
                                  >
                                    {user.geotabUsername}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          )}
                        >
                          <button
                            type="button"
                            className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                          >
                            <span
                              className="truncate"
                            >{selectedUser ? selectedUser.geotabUsername : 'Select a user'}</span>
                            <Icon
                              name="caret-down"
                              key={'userDropdownIcon'}
                            />
                          </button>
                        </DropdownV2>
                      </div>
                      <div className="flex flex-col gap-y-2 w-full">
                        <p className="text-xs">Frequency</p>
                        <DropdownV2
                          key="schedule-report-interval-dropdown"
                          menu={(
                            <Menu
                              key="schedule-report-interval-menu"
                            >
                              {['Weekly', 'Biweekly', 'Monthly'].map((item: string, index: number) => {
                                return (
                                  <MenuItem
                                    key={item + `${index}`}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setInterval(item);
                                      if (item === 'Weekly' || item === 'Biweekly') {
                                        setScheduledDay('Monday');
                                      } else {
                                        setScheduledDay('01');
                                      }
                                    }}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          )}
                        >
                          <button
                            type="button"
                            className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                          >
                            <span className="truncate">{interval}</span>
                            <Icon
                              name="caret-down"
                              key={'scheduledFrequencyIcon'}
                            />
                          </button>
                        </DropdownV2>
                      </div>
                      {interval === 'Weekly' || interval === 'Biweekly'
                        ? <div className="flex flex-col gap-y-2 w-full">
                          <p className="text-xs">Day</p>
                          <DropdownV2
                            key="schedule-report-day-dropdown"
                            menu={(
                              <Menu
                                key="schedule-report-day-menu"
                              >
                                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((item: string, index: number) => {
                                  return (
                                    <MenuItem
                                      key={item + `${index}`}
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setScheduledDay(item);
                                      }}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            )}
                          >
                            <button
                              type="button"
                              className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                            >
                              <span className="truncate">{scheduledDay}</span>
                              <Icon
                                name="caret-down"
                                key={'scheduledDayIcon'}
                              />
                            </button>
                          </DropdownV2>
                        </div>
                        : <div className="flex flex-col gap-y-2 w-full">
                          <p className="text-xs">Day</p>
                          <DropdownV2
                            key="schedule-report-day-dropdown"
                            menu={(
                              <Menu
                                key="schedule-report-day-menu"
                                style={{ maxHeight: '300px', overflowY: 'auto' }}
                              >
                                {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28']
                                  .map((item: string, index: number) => {
                                    return (
                                      <MenuItem
                                        key={`${item}` + `${index}`}
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setScheduledDay(item);
                                        }}
                                      >
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                              </Menu>
                            )}
                          >
                            <button
                              type="button"
                              className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                            >
                              <span className="truncate">{scheduledDay}</span>
                              <Icon
                                name="caret-down"
                                key={'scheduledDayIcon'}
                              />
                            </button>
                          </DropdownV2>
                        </div>}
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <p className="font-bold text-sm">Alert Types</p>
                      {alertTypes.map((alert) => (
                        <AlertType
                          key={alert.key}
                          alertKey={alert.key}
                          label={alert.label}
                          checked={alerts[alert.key as keyof TransactionAlerts]}
                          addAlert={addAlert}
                        />
                      ))}

                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex items-end gap-4 min-w-[60%] mt-6">
                    <button
                      disabled={disabled}
                      className="transition text-sm h-[28px] px-8 rounded-full bg-custom-blue-normal hover:bg-custom-blue-hover text-white"
                      type="submit"
                    >
                      {t('Submit')}
                    </button>
                    {showError && <p className="text-error-3">{errorMessage}</p>}
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default withTranslation()(ScheduleReportModal);
