import React, { useEffect, useState } from 'react';
import Icon from '../../../ui/shared/Icon';
import IconButton from '../../../ui/shared/IconButton';
import DeleteItemModal from '../../../ui/shared/Modals/DeleteItem.modal';
import SearchButton from '../../../ui/shared/SearchButton';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import ImportFuelCardsModal from '../../../ui/shared/Modals/fuel-cards/ImportFuelCards.modal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGrid,
  type GridColDef,
  type GridInitialState,
  type GridRenderCellParams,
  GridToolbar
} from '@mui/x-data-grid';
import CssBaseline from '@mui/material/CssBaseline';
import FuelCardEditorModal from '../../../ui/shared/Modals/fuel-cards/FuelCardEditor.modal';
import { openToast } from '../../../helpers/toast';
import { appState, useSignal, handleDataGridStateChange } from '../../../index';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import FuelCardSettingsModal from './FuelCardSettings.modal';
import { fetchUniqueAccountToFcp } from '../../../helpers/api';
import {
  type AccountToFcp,
  type DisplayedType,
  type FuelCard,
  type FuelCardEditorMethod,
  type FuelCardResponse
} from '../../../types/fcp.d';
import { formatOptions, transformFuelCards } from '../../../helpers/fcp';
import Tooltip from '@mui/material/Tooltip';
import { defaultTransactionAlerts } from '../../../helpers/types';

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

interface PaginationModel {
  page: number;
  pageSize: number;
}

const FuelCards: React.FC = () => {
  const updatedAccountId = useSignal(appState.accountId);
  const [fuelCards, setFuelCards] = useState<FuelCard[]>([]);
  const [accountToFcpData, setAccountToFcpData] = useState<AccountToFcp[]>([]);
  const [displayedType, setDisplayedType] = useState<DisplayedType>('both');
  const [hasFetchedData, setHasFetchedData] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set<number>());
  const [activeFuelCard, setActiveFuelCard] = useState<FuelCard | null>(null);
  const [method, setMethod] = useState<FuelCardEditorMethod>('add');
  const [loading, setLoading] = useState(false);
  const [isCompletedImport, setCompletedImport] = useState(false);
  const [uniqueIdentifierLabel, setUniqueIdentifierLabel] = useState<string>('');
  const [rowCount, setRowCount] = useState<number>(0);
  const [rowCountState, setRowCountState] = useState<number>(rowCount);
  const [typingTimeout, setTypingTimeout] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 50
  });

  const handleImportCompleted = (): void => {
    setCompletedImport(true);
  };

  const fetchFuelCards = async (model: PaginationModel, searchQuery: string): Promise<FuelCardResponse[] | null> => {
    const { page, pageSize } = model;
    const params = {
      user_id: appState.userId.value,
      account_id: appState.accountId.value,
      page: page + 1,
      pageSize,
      search: searchQuery
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/fuel-cards-minimized`, params);
      if (response.data.data) {
        setRowCount(response.data.meta.total);
        return response.data.data;
      }
      setRowCount(0);
    } catch (error: any) {
      let message = 'Error fetching fuel cards';
      // Ensure only this specific error message can overwrite the default message
      if (error?.response?.data?.message === 'Account has no primary user') {
        message = 'Account has no primary user';
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
      console.error('Error fetching fuel cards:', error);
      setLoading(false);
    }
    return null;
  };

  const determineDisplayTypes = (accountToFcpData: AccountToFcp[]): [string, DisplayedType] => {
    let samePreferredMatch = true;
    let sameUniqueField = true;
    if (accountToFcpData?.length > 1) {
      const firstProvider: AccountToFcp = accountToFcpData[0];
      accountToFcpData.forEach((fuelCardProvider: AccountToFcp): void => {
        if (fuelCardProvider.preferredMatch !== firstProvider.preferredMatch) {
          samePreferredMatch = false;
        }
        if (fuelCardProvider.uniqueField !== firstProvider.uniqueField) {
          sameUniqueField = false;
        }
      });
    }
    return [
      !sameUniqueField ? 'Unique Field' : accountToFcpData[0].uniqueField,
      !samePreferredMatch ? 'both' : accountToFcpData[0].preferredMatch
    ];
  };
  const fetchData = async (model: PaginationModel, searchQuery: string = ''): Promise<void> => {
    setLoading(true);
    const [fuelCardData, accountToFcpData] = await Promise.all([
      fetchFuelCards(model, searchQuery),
      fetchUniqueAccountToFcp(appState.accountId.value)
    ]);
    if (!accountToFcpData) {
      console.error('No fuel card providers found for this account: ', appState.accountId.value);
      setLoading(false);
      return;
    }
    if (!fuelCardData) {
      console.error('No fuel cards found for this account: ', appState.accountId.value);
      setLoading(false);
      return;
    }
    const [uniqueFieldLabel, preferredMatch] = determineDisplayTypes(accountToFcpData);
    setFuelCards(transformFuelCards(fuelCardData, accountToFcpData));
    setUniqueIdentifierLabel(t(formatOptions(uniqueFieldLabel)));
    setDisplayedType(preferredMatch);
    setAccountToFcpData(accountToFcpData);
    setHasFetchedData(true);
    setLoading(false);
  };

  const setAddData = (): void => {
    if (displayedType) {
      setActiveFuelCard({
        id: -1,
        accountId: appState.accountId.value,
        fuelCardAlertsToIgnore: { id: -1, ...defaultTransactionAlerts },
        match: { id: -1, name: '' },
        uniqueIdentifier: '',
        fuelType: '',
        fuelCardProvider: null,
        preferredMatch: null
      });
    }
  };

  const onFuelCardSubmission = (fuelCard: FuelCard, method: FuelCardEditorMethod): void => {
    setLoading(true);
    if (method === 'edit') {
      setFuelCards((prevFuelCards: FuelCard[]) => {
        return prevFuelCards.map((prevFuelCard: FuelCard): FuelCard => {
          if (prevFuelCard.id === fuelCard.id) {
            return fuelCard;
          }
          return prevFuelCard;
        });
      });
    } else {
      setFuelCards([...fuelCards, fuelCard]);
    }
    setLoading(false);
  };

  const deleteSelectedFuelCards = async (): Promise<void> => {
    const selectedRowsArray: number[] = Array.from(selectedRows);
    if (!selectedRowsArray.length) {
      return;
    }
    setLoading(true);
    const url: string = `${process.env.REACT_APP_API_URL}/api/delete-selected-fuel-cards`;
    const params = {
      account_id: appState.accountId.value,
      selectedCardIds: selectedRowsArray
    };
    try {
      await axios.post(url, params);
      setFuelCards((prevData: FuelCard[]) => prevData.filter((card: FuelCard) => !selectedRowsArray.includes(card.id)));
      setSelectedRows(new Set());
      openToast({
        type: 'success',
        label: t('The selected fuel cards have been deleted'),
        autoClose: 2000,
        theme: 'dark'
      });
    } catch (error) {
      openToast({ type: 'error', label: t('Error deleting fuel card'), autoClose: 2000, theme: 'dark' });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isNumber = (str: string): boolean => {
    return /^[0-9]*$/.test(str);
  };

  const getDynamicColumn = (): any => {
    return {
      field: 'match',
      headerName: t(displayedType === 'vehicle'
        ? t('Vehicle')
        : displayedType === 'driver'
          ? t('Driver')
          : t('Vehicle/Driver')),
      width: 300,
      valueGetter: (params: GridRenderCellParams) => params.value.name,
      renderCell: (params: GridRenderCellParams) => params.value.name
    };
  };

  const getDynamicUniqueIdentifierColumn = (): GridColDef => {
    return {
      field: 'uniqueIdentifier',
      headerName: t(uniqueIdentifierLabel) || t('Unique Field'),
      width: 200,
      sortComparator: (v1: string, v2: string): number => {
        if (v1 === v2) {
          return 0;
        } else if (isNumber(v1) && isNumber(v2)) {
          return Number(v1) > Number(v2) ? -1 : 1;
        } else if (isNumber(v1) && !isNumber(v2)) {
          return -1;
        } else if (!isNumber(v1) && isNumber(v2)) {
          return 1;
        }
        // Both are strings
        return v1 > v2 ? 1 : -1;
      }
    };
  };

  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 30,
      renderCell: (params: any) => (
        <button
          type="button"
          className="transition text-custom-blue-normal hover:text-custom-blue-hover py-2"
          onClick={(event) => {
            event.stopPropagation();
            if (!hasFetchedData) {
              return;
            }
            setActiveFuelCard({
              ...params.row
            });
            setMethod('edit');
          }}
        >
          <Icon name="edit" />
        </button>
      )
    },
    getDynamicColumn(),
    getDynamicUniqueIdentifierColumn(),
    { field: 'fuelType', headerName: t('Fuel Type'), width: 170 },
    { field: 'fuelCardProvider', headerName: t('Provider'), width: 150 }
  ].filter(Boolean); // This will remove any null entries

  const handleSearchQueryChange = (searchQuery: string): void => {
    window.clearTimeout(typingTimeout);

    // Waits until user is finished typing
    const newTimer: number = window.setTimeout((): void => {
      setSearchQuery(searchQuery.trim());
      void fetchData(paginationModel, searchQuery.trim());
    }, 1500);
    setTypingTimeout(newTimer);
  };

  // First page load and pagination changes, or account has changed likely due to a seperate tabs/sessions on the same machine
  useEffect(() => {
    void fetchData(paginationModel, searchQuery);
  }, [paginationModel, updatedAccountId, searchQuery]);

  // Reload on import
  useEffect(() => {
    if (isCompletedImport) {
      void fetchData(paginationModel);
      setCompletedImport(false);
    }
  }, [isCompletedImport]);

  // Change in row count
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount]);

  // useEffect(() => {
  //   console.log(fuelCards);
  // }, [fuelCards]);

  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center loading items-center bg-opacity-50 bg-black"
        >
          <CircularProgress />
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="credit-card" />
            <span>{t('Fuel Cards')}</span>
          </div>
          <div className="flex items-center gap-x-3">
            {displayedType === 'driver' || displayedType === 'both'
              ? <Tooltip title={t('Cannot search by driver name at this time')}>
                <div className="flex items-center gap-x-2">
                  <Icon
                    name="info-circle"
                    className="tooltip-orange"
                  />
                </div>
              </Tooltip>
              : null}
            <SearchButton
              onChange={handleSearchQueryChange}
            />
            <IconButton
              icon="plus"
              onClick={setAddData}
            />
            <ImportFuelCardsModal
              onImportCompleted={handleImportCompleted}
              accountToFcpData={accountToFcpData}
            />
            <DeleteItemModal
              header={t('Delete fuel cards')}
              message={
                `${t('Are you sure you want to delete the selected fuel cards?')}
                \n${Array.from(selectedRows)
                  .map(rowId => {
                    const fuelCard = fuelCards.find(card => card.id === rowId);
                    return fuelCard ? `${fuelCard.fuelCardProvider}-${fuelCard.uniqueIdentifier}` : rowId;
                  })
                  .join('\n')}
                `
              }
              onDelete={() => {
                void deleteSelectedFuelCards();
              }}
            />
            {(appState.userRole.value === 'super_admin' || (appState.parentData.value ? JSON.parse(appState.parentData.value)?.userRole === 'super_admin' : false)) &&
            <FuelCardSettingsModal accountToFcpData={accountToFcpData} />}
          </div>
        </div>
        <div style={{ marginTop: '10px' }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DataGrid
              onStateChange={(state: GridInitialState): void => {
                handleDataGridStateChange('fuelCards', state);
              }}
              initialState={appState.dataGridColumnState.value.fuelCards ?? {}}
              disableDensitySelector={true}
              localeText={{
                noRowsLabel: t('No rows'),
                toolbarColumns: t('Columns'),
                toolbarColumnsLabel: t('Columns'),
                toolbarDensity: t('Density'),
                toolbarDensityLabel: t('Density'),
                toolbarDensityCompact: t('Compact'),
                toolbarDensityStandard: t('Standard'),
                toolbarDensityComfortable: t('Comfortable'),
                toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                MuiTablePagination: {
                  labelRowsPerPage: t('Rows per Page'),
                  labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                }
              }}
              pagination
              rowCount={rowCountState}
              rows={[...fuelCards].sort((fuelCardA: FuelCard, fuelCardB: FuelCard) => {
                if (fuelCardA.match.name > fuelCardB.match.name) return 1;
                if (fuelCardA.match.name < fuelCardB.match.name) return -1;
                return 0;
              })}
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={(newModel) => {
                setPaginationModel(newModel);
              }}
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
              density="compact"
              slotProps={{
                toolbar: {
                  showQuickFilter: false,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true }
                }
              }}
              slots={{ toolbar: GridToolbar }}
              onRowClick={(params) => {
                const newSelectedRows = new Set(selectedRows);
                if (newSelectedRows.has(Number(params.id))) {
                  newSelectedRows.delete(Number(params.id));
                } else {
                  newSelectedRows.add(Number(params.id));
                }
                setSelectedRows(newSelectedRows);
              }}
              getRowClassName={(params) => {
                return selectedRows.has(Number(params.id)) ? 'selected-row' : '';
              }}
              className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
              sx={{
                height: '50rem',
                '& .MuiDataGrid-row': {
                  borderRadius: '80px',
                  backgroundColor: '#242838',
                  marginTop: '4px',
                  borderBottom: 'none'
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                '& .MuiDataGrid-cell': {
                  border: 'none'
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderRadius: '80px',
                  backgroundColor: '#242838',
                  borderBottom: 'none',
                  marginBottom: '10px'
                },
                border: 0
              }}
            />
          </ThemeProvider>
        </div>
        {activeFuelCard &&
          <FuelCardEditorModal
            hasFetchedData={hasFetchedData}
            uniqueIdentifierLabel={uniqueIdentifierLabel}
            accountToFcpData={accountToFcpData}
            activeFuelCard={activeFuelCard}
            method={method}
            onHide={() => {
              setActiveFuelCard(null);
              setMethod('add');
            }}
            onFuelCardSubmission={onFuelCardSubmission}
          />}
      </div>
    </>
  );
};

export default withTranslation()(FuelCards);
